import React from 'react';
import '../App.css';
import './Shared.css';
import './Governance.css';

const Governance = () => {
  return (
    <div className='container'>
      <h1 className="section-heading">
        Governance
      </h1>
      <div className='content'>
        <img src={process.env.PUBLIC_URL + '/images/Governance.png'} alt="Governance Image" />

        <div className="section" style={{ textAlign: 'center' }}>
          <h2 className="section-subheading">
            <u>Visionary Leadership</u>
          </h2>
          <p>
            At Ishaan Foundation, we pride ourselves on embodying a culture of dynamic leadership, unwavering accountability, and effective management. These principles form the bedrock of our commitment to creating a brighter future for special children. Our organization is led by individuals who view leadership not merely as a title but as a profound commitment to guiding us toward our mission.
          </p>
        </div>

        <div className="section" style={{ textAlign: 'center' }}>
          <h2 className="section-subheading">
            <u>Trustworthiness</u>
          </h2>
          <p>
            Accountability is the cornerstone of our governance structure. We firmly believe in transparency, responsibility, and integrity in every action we take. Both leaders and team members are held accountable for their roles and contributions. Through regular assessments, open communication, and a culture of responsibility, Ishaan Foundation operates at the highest standards of accountability, consistently earning the trust of our stakeholders.
          </p>
        </div>

        <div className="section" style={{ textAlign: 'center' }}>
          <h2 className="section-subheading">
            <u>Stewardship</u>
          </h2>
          <p>
            Effective management is paramount for the success of our initiatives. Ishaan Foundation is fortunate to be guided by a capable and efficient management team. This team oversees day-to-day operations, project implementation, and strategic planning. With a focus on efficiency, innovation, and adaptability, our management ensures optimal resource utilization and the precise execution of projects, thus achieving the desired impact.
          </p>
          <div style={{ height: '4em' }}></div>
        </div>
      </div>
    </div>
  );
}

export default Governance;
