import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './Shared.css';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className='container'>
      <h1 className="section-heading">About Us</h1>

      <div className='content'>
        <div className="section" style={{ textAlign: 'center' }}>
          <h2 className="section-subheading">
            <u>Our Story: Rise and Shine</u>
          </h2>
          <p>
            Ishaan Foundation stands as a symbol of hope and support for special children within our community.
            We have started a movement that builds capacities and nurtures the unique abilities of special children,
            helping them blossom into superstars that light up the universe they live in.
            That’s because we don't believe in limitations, only aptitudes and possibilities.
          </p>
        </div>

        <div className="section" style={{ textAlign: 'center' }}>
  <h2 className="section-subheading" style={{ textAlign: 'center' }}>
    <u>Our Approach: Giving them Wings to Reach Out for More</u>
  </h2>
  <ul style={{ textAlign: 'left', listStylePosition: 'inside', paddingInlineStart: '1.5em' }}>
    <li>Harmony through Music: Sponsoring music lessons for gifted children from less fortunate backgrounds.</li>
    <li>Healing through Art: Connecting parents and children through art therapy, unleashing hidden potential.</li>
    <li>Soaring High: Empowering teenagers with special needs to believe in themselves and embrace their talents.</li>
    <li>Nurturing and Care: Providing financial support for critical surgeries that give children a brighter future.</li>
    <li>Together We Rise: Partnering with organizations that champion mobility and inclusion.</li>
  </ul>
</div>


        <div style={{ height: '2.5em' }}></div>

        <div className="section" style={{ textAlign: 'center' }}>
          <h2 className="section-subheading">
            <u>Vision and Mission: We Spark Dreams</u>
          </h2>
          <p>
            We help children discover their special gifts, whether it's creating a work of art performing on stage,
            or mastering life skills. We channelize the right resources towards them and make them aware of their special skills.
            We help them pursue their dreams, big or small.
            Our aim is to be impactful rather than just funding programs or offering financial assistance.
            We want to help each special child paint a bright future for themselves and light up the world.
            We believe every light deserves to shine, every dream deserves to take flight.
          </p>
        </div>

        

        <div className="section">
          <h2 className="section-subheading">
            <p>Ishaan foundation always has their back.</p>
            <div style={{ height: '2em' }}></div>

            <div className='navigation-links'>
        <Link to="/TermsAndConditions" className="styled-link">
          Explore our Terms and Conditions
        </Link>
      </div>

      <div style={{ height: '1em' }}></div>

      <div className='navigation-links'>
        <Link to="/Privacy" className="styled-link" title="Discover more about our commitment to your privacy">
           Learn about our Privacy Policy
        </Link>
      </div>

            <div style={{ height: '4em' }}></div>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
