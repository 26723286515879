import React from 'react';
import '../App.css';
import './Shared.css';

const Projects = () => {
  return (
    <div className='container'>
      <h1 className="section-heading">Projects</h1>

      <div className='content'>
        <div className="section">
          <h2 className="section-subheading" style={{ textAlign: 'center'}}>
            <u>Harmony through Music</u>
          </h2>
  <div style={{ height: '1.5em' }}></div>
          <p>
            In the pursuit of nurturing the unique talents of special children, Ishaan Foundation is dedicated to fostering a harmonious environment through the power of music. Our project involves sponsoring music lessons for gifted children coming from less fortunate backgrounds. By providing access to musical education, we aim to not only enhance their artistic abilities but also offer a therapeutic outlet for emotional expression.
          </p>
        </div>
  <div style={{ height: '2.5em' }}></div>

        <div className="section">
        <h2 className="section-subheading" style={{ textAlign: 'center'}}>
            <u>Healing through Art</u>
          </h2>
  <div style={{ height: '1.5em' }}></div>
          <p>
            Ishaan Foundation recognizes the profound impact of art therapy on the well-being of special children and their families. Our project, "Healing through Art," is designed to connect parents and children through artistic expression, unlocking hidden potential and fostering stronger emotional bonds. Through various art therapy initiatives, we create a supportive space where creativity becomes a tool for healing.
          </p>
        </div>
  <div style={{ height: '2.5em' }}></div>

        <div className="section">
          <h2 className="section-subheading" style={{ textAlign: 'center'}}>
            <u>Soaring High</u>
          </h2>
  <div style={{ height: '1.5em' }}></div>
          <p>
            Empowering teenagers with special needs is at the core of our project "Soaring High." We believe in instilling confidence and self-belief in these young individuals, encouraging them to embrace their unique talents. Through mentorship programs, skill development, and a supportive community, we strive to empower these teenagers to overcome challenges and reach their full potential.
          </p>
        </div>
  <div style={{ height: '2.5em' }}></div>

        <div className="section">
          <h2 className="section-subheading" style={{ textAlign: 'center'}}>
            <u>Nurturing and Care</u>
          </h2>
  <div style={{ height: '1.5em' }}></div>
          <p>
            Financial barriers should never hinder a child's chance at a brighter future. Ishaan Foundation's project "Nurturing and Care" is dedicated to providing financial support for critical surgeries that are essential for a child's well-being. By addressing healthcare needs, we aim to ensure that these children can lead healthier lives, allowing their potential to blossom.
          </p>
        </div>
  <div style={{ height: '2.5em' }}></div>

        <div className="section">
          <h2 className="section-subheading" style={{ textAlign: 'center'}}>
            <u>Together We Rise</u>
          </h2>
  <div style={{ height: '1.5em' }}></div>
          <p>
            Collaboration is key to creating a more inclusive world. "Together We Rise" is a project where Ishaan Foundation partners with organizations that champion mobility and inclusion. By working together, we can amplify our impact and create a more accessible environment for special children. This collaborative effort aims to break down barriers and build a world where everyone has the opportunity to rise.
          </p>
          <div style={{ height: '4em' }}></div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
