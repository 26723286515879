import React from 'react';
import { Link } from 'react-router-dom';
import './Privacy.css';
export default function Privacy() {
  return (
    <div className='container'>
        <h1 className='section-heading'>Privacy Policy</h1>
        <div className='contents'>
            
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Ishaan Foundation Website Privacy Policy: Last Updated on January 2024</h2>
<p>            
Privacy is an important consideration for and we are committed to protecting
the privacy of all visitors to this site.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>No Personal Information required</h2>
<p>
To access the base content on our site, you will not be required to provide any
personal information. However, we use Google Analytics to measure website
traffic and improve our website design, and Google does use a cookie to
collect anonymous traffic tracking data. We do not collect personally
identifiable data from users based on visits to our web pages. However, if a
visitor registers with us or sends us an email, we will have access to additional
information and discuss such cases in more detail below.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>General Information for Casual Visitors</h2>
<p>
When you request information from our website, the web server uses your IP
address to communicate with you, and this minimal information is always
retained for a period of time on our server. Your computer can be identified
from the IP address while you are connected to the Internet, although it
normally would not identify you individually as the user. As a matter of course,
our site will automatically gather certain general information such as the
number of visitors on the site and the most frequently visited areas. However,
this information cannot be used to identify you personally. We use this
aggregate information to try to improve the site, making it more useful and
usable.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Personal Information of some Ishaan Foundation Users </h2>
<p>
The website showcases some important user experiences which might benefit
other potential Ishaan Foundation users. Please note that whatever minimal
personal information about the user is shared has been done after the users
consent.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Information for Other Visitors:</h2>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Email Activities</h2>
<p>
If you choose to send us email messages, we will use the messages only to
respond to them and will not share them with anyone, unless you give us
explicit permission to do so or by order of an appropriate court of law directing
us to do so.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>No Disclosure of Information to Third Parties</h2>
<p>
We don’t sell or provide our donor lists to third parties, other than those we use
for specific support of Ishaan Foundation efforts (such as Google Analytics
and Phoenix) and who have agreed to not provide such information to other
third parties. As a general requirement of law, we will respond to documents in
a legal proceeding, such as court orders or subpoenas, if we receive any.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 className="left-aligned-heading">Other sites</h2>
<p>
We have no control over the privacy of external web sites which might be
linked from this web site. We reference other web sites that you may wish to
visit, but this Privacy Policy does not apply beyond this site.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Privacy Policy Changes</h2>
<p>
We may decide to change the Privacy Policy but, if we choose to do so, we will
post the changes here.
If you have any questions about the Privacy Policy, or about any other aspect
of operation of the site, please contact us at info@ishaanfoundation.in and we
will respond confidentially.
The terms and conditions regarding using Ishaan Foundation website
(https://www.ishaanfoundation.in) are mentioned below.
Ishaan Foundation holds the discretion to modify the users terms and
conditions if need be. However, it shall be effective within a month of posting it
in this section whenever it happens.

This is an agreement between Ishaan Foundation and the user/visitor to the
website. This Agreement governs the use of Ishaan Foundation website, which
includes contents like text, information, images as well as all services available
to the users through the same.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Who becomes a User?</h2>
<p>
The services are available only to individuals (excluding minors) who are
eligible for legally binding contracts under applicable law. Our services are not
available to temporarily or indefinitely suspended Ishaan Foundation users.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>How one should use Ishaan Foundation Website Content</h2>
<p>
The content consists of copyrighted works proprietary to Ishaan Foundation
or to partners/ third parties who have provided us with content. You may
download and print a single copy of the content solely for your use in
connection with your internal non-commercial use. Any content you
download or print may not be altered in any way and must contain all
copyright and proprietary rights notices that were contained in such content.
Any unauthorized or unapproved use of any of the content constitutes
copyright infringement and subjects the user to all civil and criminal penalties
provided for under domestic and international copyright laws and treaties.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Personal Information</h2>
<p>
Personal Information is defined as any information the user provides us. The
user is solely responsible for such personal information, and we act as a
passive conduit for receiving and processing your requests based on the
user’s personal information.
With respect to Personal Information (or any items listed therein):
</p>
<div style={{ height: '1.5em' }}></div>
<ul>
  <li>Shall not be false, inaccurate or misleading;</li>
  <li>Shall not be fraudulent;</li>
  <li>Shall not infringe any third party’s copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;</li>
  <li>Shall not violate any law, statute, ordinance or regulation (including without limitation those governing export control, consumer protection, unfair competition, anti-discrimination or false advertising);</li>
  <li>Shall not be defamatory, unlawfully threatening or unlawfully harassing;</li>
  <li>Shall not contain any viruses, Trojan horses, worms, time bombs, cancel bots or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information;</li>
  <li>Shall not create liability for us or cause us to lose (in whole or in part) the services of our ISPs (Internet Service Providers) or other suppliers.</li>
</ul>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Rights &amp; Privileges of use of the user’s Personal Information</h2>
<p> 
In order to enable Ishaan Foundation to use the personal information you
supply us with, so that we are not violating any rights you might have in that
information, you agree to grant us a non-exclusive, worldwide, perpetual,
irrevocable, royalty-free, sub licensable (through multiple tiers) right to
exercise the copyright and publicity rights (but no other rights) you have in
personal information, in any media now known or not currently known, with
respect to personal information. Ishaan Foundation will only use personal
information in accordance with our Privacy Policy.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Information Accuracy</h2>
<p>
The site &amp; certain services will be accessible to users of the website as
identified in the Registration Forms or relevant sections. All user information
provided to us, including without limitation the information on the Registration
Forms, must be current, accurate, and complete. If we at any time discover
any error or omission in the information you provide to us, we may, at our
option, terminate the right to access and use the site by any user. The user is
responsible for the acts or omissions by the use of the site, and for any
damages incurred by the user as a result thereof.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Business Relationship</h2>
<p>
You as the user and Ishaan Foundation are independent contractors, and no
agency, partnership, joint venture, employee-employer, franchiser-franchisee
or other business relationship is intended or created by this Agreement.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Intellectual Property</h2>
<p>
Ishaan Foundation owns all worldwide rights, titles and interests in and to the
site. You may create a bookmark in your browser to the home page of the
website. Otherwise, you may not create a link to the site without our prior

written approval. All rights not expressly granted in this Agreement are
reserved to us. No other rights or licenses whether express, implied, arising by
estoppels, or otherwise are conveyed or intended by this Agreement. To
facilitate your access to and use of our site or portions thereof, we may make
certain software available to you. The terms of your use of such software will
be stated separately in a license agreement that will be included with the
software. You agree to only use such software in a manner permitted
pursuant to such license agreements.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Damage to Website Content</h2>
<p>
You agree that you will not use any robot, spider, other automatic device, or
manual process to monitor or copy our web pages or the content contained
herein without our prior expressed written permission. You agree that you will
not use any device, software or routine to interfere or attempt to interfere with
the proper working of the Ishaan Foundation website. You agree that you will
not take any action that imposes an unreasonable or disproportionately large
load on our infrastructure. Much of the information on our site is updated on a
real time basis and is proprietary or is licensed to Ishaan Foundation by our
users or third parties. You agree that you will not copy, reproduce, alter,
modify, create derivative works, or publicly display any content from our
website without the prior expressed written permission of Ishaan Foundation
or the appropriate third party.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Breach of Agreement</h2>
<p>
Without limiting other remedies, we may immediately issue a warning,
temporarily suspend, indefinitely suspend or terminate your
membership/subscription and refuse to provide our services to the user: (a) if
the user breaches this Agreement or the documents it incorporates by
reference; (b) if we are unable to verify or authenticate any information the
user provides to us; or (c) if we believe that the userï¿½s actions may cause
legal liability for you, our users or us.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Termination of Agreement</h2>
<p>
Your right to access and use the site immediately terminates without further
notice upon the user’s breach of this Agreement. We may terminate this
Agreement and/or the user’s right to use the site at any time, with or without

cause. We reserve the right to discontinue or make changes to the site at any
time.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Privacy of Members</h2>

<p>Ishaan Foundation is dedicated to protecting the privacy of its users.</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Warranty of Services</h2>
<p>
We and our partners provide Ishaan Foundation website and services “AS IS”
and without any warranty or condition, express, implied or statutory. We and
our associates specifically disclaim any implied warranties of with respect to
any product or service.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Limit of Liability &amp; Exclusion of Damages</h2>
<p>
Ishaan Foundation will not be responsible or liable to any user of the website
or its services for any direct, compensatory, indirect, incidental, consequential,
special, and exemplary or punitive damages for any reason such as use of
content, services, errors, inaccuracies, omissions, defects, untimeliness,
security breaches or any other causes.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Indemnity</h2>
<p>
The user has to agree to indemnify and hold us and our subsidiaries, affiliates,
officers, directors, agents, and employees, harmless from any claim or
demand, including reasonable attorneys arising from or incurred as a result
of, or in any manner related to
</p>
<div style={{ height: '1.5em' }}></div>
<ul>
<li>The user’s breach of the terms of this Agreement,</li>
<li>The user’s unauthorized or unlawful use of the site, and</li>
<li>The unauthorized or unlawful use of the site by any other person using
the user’s identity.</li>
</ul>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline', textAlign: 'left' }}>Arbitration</h2>
<p>
Any controversy or claim arising out of or relating to this agreement or our
services shall be settled by binding arbitration in accordance with the
commercial arbitration rules applicable to Bengaluru, India. Any such
controversy or claim shall be arbitrated on an individual basis, and shall not

be consolidated in any arbitration with any claim or controversy of any other
party. The arbitration shall be conducted at Bengaluru, Karnataka, India and
judgment on the arbitration award may be entered into any court having
jurisdiction thereof. Either the user or Ishaan Foundation may seek any interim
or preliminary relief from a court of competent jurisdiction at Bengaluru, India
necessary to protect the rights or property of the user or Ishaan Foundation
pending the completion of arbitration.
</p>
<div className='navigation-links'>
        <Link to="/TermsAndConditions" className="styled-link">
          Explore our Terms and Conditions
        </Link>
      </div>
<div style={{ height: '7em' }}></div>        
        </div>
    </div>
  )
}


