import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling } from '@fortawesome/free-solid-svg-icons';

export default function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          {/* Logo */}
          <RouterLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <FontAwesomeIcon icon={faSeedling} />
          </RouterLink>

          {/* Hamburger Menu Icon */}
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>

          {/* Navigation Links */}
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <RouterLink to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink to='/AboutUs' className='nav-links' onClick={closeMobileMenu}>
                About Us
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink to='/Governance' className='nav-links' onClick={closeMobileMenu}>
                Governance
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink to='/Volunteer' className='nav-links' onClick={closeMobileMenu}>
                Volunteer
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink to='/DonateUs' className='nav-links' onClick={closeMobileMenu}>
                Donation
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink to='/Projects' className='nav-links' onClick={closeMobileMenu}>
                Projects
              </RouterLink>
            </li>
            <li className="nav-item">
              <RouterLink to='/Contact' className='nav-links' onClick={closeMobileMenu}>
                Contact
              </RouterLink>
            </li>
            <li classname="nav-item">
              <RouterLink to='/TermsAndConditions' className='nav-links' onClick={closeMobileMenu}>
                Terms & Conditions
              </RouterLink>
            </li>
            <li classname="nav-item">
              <RouterLink to='/Privacy' className='nav-links' onClick={closeMobileMenu}>
                Privacy
              </RouterLink>
            </li>
          </ul>

          {/* Donate Button */}
          <a href="https://rzp.io/l/563rQLdoRm" className="custom-button">
            Donate
          </a>
        </div>
      </nav>
    </>
  );
}
