import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './Shared.css';

const Volunteer = () => {
  return (
    <div className='container'>
      <h1 className="section-heading">Volunteer</h1>
      
      <div className='content'>
        <div className="section" style={{ textAlign: 'center' }}>
          <p>
            Join Us, Paint a Brighter Future. At Ishaan Foundation, we believe in the transformative power of collaboration. Our journey is fueled by a shared vision—a world where special abilities are not just recognized but celebrated, where every child, regardless of their challenges, has the opportunity to flourish.
          </p>
          <p>
            We invite you to be a part of this impactful journey. If you resonate with our commitment to creating an inclusive world, where the unique talents of every child are nurtured and celebrated, then let's partner together! Because, in unity, we can weave a tapestry of hope that brightens the lives of special children, one child at a time.
          </p>
          <p>
            Together, we can build a world of hope where diversity is cherished, where every individual, regardless of their abilities, contributes to the rich mosaic of humanity. Let's collaborate, let's innovate, and let's make a lasting difference in the lives of these extraordinary children.
          </p>
          <p>
            Because at Ishaan Foundation, we believe that by joining forces, we can paint a brighter and more inclusive future—one stroke of kindness, one act of collaboration, and one child at a time.
          </p>
        </div>
      </div>

      <div className='cta' style={{ textAlign: 'center' }}>
  <div className="section">
    <p>
      To share your talent or skills, write to us 
      <Link to="/contact">
        <button className="cta-button" style={{ marginTop: '20px', marginBottom: '80px' }}>
          CLICK HERE
        </button>
      </Link>
    </p>
    <div style={{ height: '4em' }}></div>
  </div>
</div>

    </div>
  );
}

export default Volunteer;
