import React from 'react';
import { Link } from 'react-router-dom'
import '../App.css';
import './Shared.css';
import './Privacy.css';

export default function TermsAndConditions() {
  return (
    <div className='container'>
        <h1 className='section-heading'>Terms & Conditions</h1>
        <div className='contents'>
            
        <h2 style={{ textDecoration: 'underline' }}>Terms &amp; Conditions</h2>
            <div style={{ height: '2.5em' }}></div>
            <p>
Ishaan Foundation does not guarantee continuous, uninterrupted or secure
access to our services, and operation of our site may be interfered with by
numerous factors outside of our control. Our failure to act with respect to a
breach by the user or others does not waive our right to act with respect to
subsequent or similar breaches. This Agreement sets forth the entire
understanding and agreement between us with respect to the subject matter
hereof
</p>
<p>
Please read this page carefully before exploring our website.
Ishaan Foundation (“Ishaan Foundation” or “The NGO”) maintains this website
to provide information to the internet community. Such information may be
subject to amendment and updating without notice.
The information contained in this website is for general information purposes
only. The information is provided by Ishaan Foundation and whilst our
endeavour is to keep the information up-to-date and correct, we make no
representations or warranties of any kind, express or implied, about the
completeness, accuracy, reliability, suitability or availability with respect to the
website or the information, products, services, or related graphics contained
on the website for any purpose. Any reliance you place on such information is
therefore strictly at your own risk.
</p>
<p>
In no event will we be liable for any loss or damage including without
limitation, indirect or consequential loss or damage, or any loss or damage
whatsoever arising from loss of data or profits are arising out of or in
connection with the use of this website. Through this website you are able to
link to other websites which are not under the control of Ishaan Foundation.
We have no control over the nature, content and availability of those sites. The
inclusion of any links does not necessarily imply a recommendation or
endorse the views expressed within them.
Every effort is made to keep the website up and running smoothly. However,
Ishaan Foundation takes no responsibility for and will not be liable for the
website being temporarily unavailable due to technical issues beyond our
control.
</p>
<p>
Access to and use of this information is subject to the following terms and
conditions: The sole purpose of this website is to provide information to the
reader. It is not intended to form the basis of any investment decision.
Whilst the information contained within this website is believed to be accurate,
no representation or warranty, express or implied, is or will be given by Ishaan
Foundation as to the accuracy or completeness of this information or opinions
contained herein.
</p>
<div style={{ height: '2.5em' }}></div>
<h2 style={{ textDecoration: 'underline' }}>DATA PROTECTION</h2>
<p>
Any personal information submitted via this website, whether by email or
other means, will be used solely by Ishaan Foundation and will not be
disclosed to any third parties. If you wish to view, amend or delete your
personal data please contact us.
</p>
<div style={{ height: '2.5em' }}></div>
<h2 style={{ textDecoration: 'underline' }}>Fundraising and Donor Privacy Policies & Procedures</h2>
<div style={{ height: '2em' }}></div>
<h2 style={{ textDecoration: 'underline' }}>Introduction</h2>
<p>
Ishaan Foundation is a non-profit organization committed to the empower the
children with financial, Socio-economical &amp; Societal challenges. All donations
solicited on behalf of Ishaan Foundation shall be used to further this mission
and follow the policies and procedures outlined below.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline' }}>Fundraising Policy</h2>
<p>
Funds shall be solicited in a respectful manner and without pressure. All third
parties not directly affiliated with Ishaan Foundation who wish to solicit funds
on behalf of the agency must acquire written permission from Ishaan
Foundation development office prior to beginning any fundraising activities.
Donor-designated restrictions on contributions shall be honoured. Ishaan
Foundation is a non-profit organization registered under The Section 8,
Company80G act and contributions made to the are tax exempted to the
fullest extent of the law. Written tax receipts shall be issued for all donations. At
the beginning of each calendar year Ishaan Foundation shall provide its
donors with written documentation of all tax deductible gifts received during
the prior calendar year.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline' }}>Fundraising Procedures</h2>
<p>
Upon receipt, all monetary donations shall be forwarded to the accounting
department for coding and recording purposes. After funds have been
accounted for by the appropriate accounting staff, a written transmittal,
check copies, cash receipts, and any other correspondence accompanying
the donation shall be circulated through the development department.
Donations are acknowledged by the development department. All monetary
donations are recorded and deposited according to accounting procedures.
All donor-designated restrictions shall be communicated to the accounting
department so that they may be recorded and governed appropriately. All
requests for donors to remain anonymous shall be honoured by flagging the
gift in the donor database as appropriate. All in-kind gifts that are accepted
by Ishaan Foundation shall be recorded and acknowledged according to
development and accounting procedures.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline' }}>Donor Privacy Policy</h2>
<p>
Any information supplied to Ishaan Foundation by donors will be used solely to
fulfil their donation and shall not be shared for any reason unless permission is
granted by the donor to share such information. All requests to remain
anonymous shall be honoured. Ishaan Foundation does not sell or share
donor lists. Donors who supply Ishaan Foundation with their postal address or
email address may be contacted periodically for solicitation purposes and/or
with information regarding upcoming events. All donors have the option of
being placed on a “once-a-year” mailing list which grants Ishaan Foundation
permission to contact them only once per year. Donors may request to be
permanently removed from Ishaan Foundation’s mailing list by contacting us
via email, phone or postal mail. All requests to be removed from Ishaan
Foundation’s mailing list shall be honoured. Donors who supply Ishaan
Foundation with their telephone number shall only be contacted by telephone
regarding donations they have made.
</p>
<div style={{ height: '1.5em' }}></div>
<h2 style={{ textDecoration: 'underline' }}>Ishaan Foundation does not participate in telephone fundraising activities.</h2>
<ul style={{ fontSize: 'larger' }}>
  <li>
    <strong>1.</strong> If a donor feels that he/she made an error in the donation amount/purpose or any other parameter,
    kindly let Ishaan Foundation know about such error as early as possible by emailing details at <a href="mailto:info@ishaanfoundation.in">info@ishaanfoundation.in</a>.
    We will try to resolve the issue in the appropriate manner.
  </li>
  <li>
    <strong>2.</strong> Donations given to Ishaan Foundation are allocated to the respective work/task appropriated by.
    If you wish to cancel the donation, kindly let us know within 24 hours.
  </li>
  <li>
    <strong>3.</strong> All donations made to Ishaan Foundation are Tax Exempt under 80G (5) of
    Income Tax in India. Donors will be provided a receipt for the donations given to Ishaan Foundation.
  </li>
</ul>
<div style={{ height: '1em' }}></div>
<div style={{ display: 'flex', justifyContent: 'center'}} className='navigation-links'>
        <Link to="/Privacy" className="styled-link" title="Discover more about our commitment to your privacy">
           Learn about our Privacy Policy
        </Link>
      </div>
<div style={{ height: '1.5em' }}></div>
        <div style={{ height: '2.5em' }}></div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
  <img
    src={process.env.PUBLIC_URL + '/images/Screenshot 2024-01-25 154233.png'}
    alt='Your Alt Text'
    style={{ maxWidth: '50%', height: 'auto' }} 
  />
</div>
<div style={{ height: '5em' }}></div>

        </div>
    </div>
  )
}
