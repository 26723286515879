import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import './Contact.css';

const Contact = () => {
  return (
    <div className="ContactUs">
      <h1>Contact Us</h1>
      <p>
        Please do not hesitate to reach out to us with any queries or concerns. We welcome the opportunity to assist you. Your satisfaction is our priority. Feel free to contact our dedicated team via{' '}
        email or phone. We value your feedback and are committed to providing exceptional service.
      </p>

      <div className="ContactDetails">
        <p>
          <FontAwesomeIcon icon={faEnvelope} /> info@ishaanfoundation.in
        </p>
        <p>
          <FontAwesomeIcon icon={faPhone} /> +91 88675 98833 
        </p>
        <div>
  <h2 style={{ color: 'black' }}>Operating Address:</h2>
  <p>
  <FontAwesomeIcon icon={faMapMarkerAlt} /> Ishaan Foundation <br />
  Apt A-302, Sterling Terraces <br />
  #3, 100 Feet Ring Road, Banashankari 3rd Stage, <br />
  Bengaluru - 560085 <br />
  Karnataka, <br />
  India.
</p>

  <p>
    <a href="https://www.google.com/maps?q=Ishaan+Foundation+Apt+A-302,+Sterling+Terraces,+Banashankari+3rd+Stage,+Bengaluru+-+560085,+Karnataka,+India" target="_blank" rel="noopener noreferrer">
      View on Google Maps
    </a>
  </p>
</div>
<div style={{ height: '5em' }}></div>
      </div>
    </div>
  );
};

export default Contact;
