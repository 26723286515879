import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import DonateUs from './pages/DonateUs';
import Volunteer from './pages/Volunteer';
import Governance from './pages/Governance';
import Projects from './pages/Projects';
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';
import BottomNavbar from './components/BottomNavbar'; // Import BottomNavbar component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return (
    <Router>
      <>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/DonateUs" element={<DonateUs />} />
          <Route path="/volunteer" element={<Volunteer />} />
          <Route path="/governance" element={<Governance />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/Privacy" element={<Privacy />} />
        </Routes>
        <BottomNavbar /> {/* Replace Footer with BottomNavbar */}
      </>
    </Router>
  );
}

export default App;
