import React, { useEffect, useState } from 'react';
import './HeroSection.css';
import '../pages/Shared.css';

const HeroSection = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Set loaded to true after the component has mounted
    setLoaded(true);
  }, []);

  return (
    <div className={`hero-container ${loaded ? 'loaded' : ''}`}>
      <h1>Ishaan Foundation</h1>

      <div className='content'>
        <p className='quote'>
          "The Sun does not shine for a few trees and flowers, but for the wide world's joy."
          <br />
          - Henry Ward Beecher
        </p>
      </div>
    </div>
  );
}

export default HeroSection;
