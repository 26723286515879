import React from 'react';
import '../App.css';
import './Shared.css';
import './DonateUs.css';

const DonateUs = () => {
  return (
    <div className='container'>
      <h1>Support Us</h1>

      <div className='content'>
        <p>
          Your contribution can make a significant impact in supporting the mission of Ishaan Foundation. By donating, you become an integral part of our journey to create a more inclusive and brighter future for special children.
        </p>
        <p>
          Every donation, regardless of size, plays a crucial role in funding various programs and initiatives that empower these children to discover and develop their unique abilities.
        </p>
        <p>
          Here are different ways you can contribute and make a difference:
        </p>
        <ul>
          <li>One-time Donation: Make a single contribution to support our ongoing projects and initiatives.</li>
          <li>Monthly Giving: Join our monthly giving program to provide consistent support for long-term impact.</li>
          <li>Corporate Sponsorship: Explore opportunities for corporate partnerships and sponsorships to amplify our reach.</li>
        </ul>
        <p>
          Your generosity fuels our efforts in nurturing the talents of special children, providing them with opportunities, and creating a more inclusive society. Together, we can make a positive change in the lives of these extraordinary individuals.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '4em' }}>
  <img
    src={process.env.PUBLIC_URL + '/images/Screenshot 2024-01-25 154233.png'}
    alt='Your Alt Text'
    style={{ maxWidth: '100%', height: 'auto' }}
  />
</div>

      </div>


    </div>
  );
}

export default DonateUs;
